.loader {
    border: 6px solid #EAF0F6;
    border-radius: 50%;
    border-top: 6px solid #FF7A59;
    width: 10px;
    height: 10px;
    animation: spinner 2s linear infinite;
    padding: 0.2rem;
    margin: 0.95rem;
}





@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }


}



.loader-container {
    float: left;
}

.loader-container p {
    font-size: 10pt;
}

/*.loader {*/
/*  max-width: 30px;*/

/*
}*/